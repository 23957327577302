// Payment
//
//  desc:
//    Payment step / page for the payment link form
//    aphrodite shares this so beware of any changes.
//    would be nice not to share this component.
//
//  props:
//    formdata:             (required) form data
//      payon:                (optional) date to pay on or start payment for processing transaction
//      amount:               (optional) amount to process
//      invoice:              (optional) invoice

//    onValidate:           (optional) a callback to update the data model
//    onDataChange:         (optional) a callback to validate the data model
//   
//    isAmountReadOnly:     (optional) indicates if the amount input field should not be editable by the user
//    isInvoiceReadOnly:    (optional) indicates if the invoice input field shoudl not be editable by the user 

import React, { Component } from 'react';
import CreditCard, {CreditCardSmartIcon} from '../../../CreditCard';
import SectionText from '../../../SectionText';
import Country from '../../../Country';
import Address from '../../../Address';
import ZipCodeInput from '../../../ZipCodeInput';
import ExpirationDateInput from '../../../ExpirationDateInput';
import SecurityCodeInput from '../../../SecurityCodeInput';
import FormInput from '../../../FormInput';
import AmountInputBox, {
  validateAmount
} from '../../../AmountInputBox';
import DatePicker, {
  validateDateFormat
} from '../../../DatePicker';
import {DateTimeUtility, Rule, Change, Validation} from '../../../Utility';
import {default as RecurringPaymentFields} from '../../RecurringPaymentFields';
import {default as ProcessingMethod, ProcessMethod} from '../../PaymentProcessMethod';
import './payment.css';
import './payment.med.css';


const VALID = true;
const INVALID = false;

class Payment extends Component {
  constructor(props){
    super(props);
    this.state = {
      validAmount: VALID,
      validPayOn: VALID,
      frequency: null,
      duration: "continual",
      numberOfPayments: null,
    }
    this.onPaymentInvoiceChange = this.onPaymentInvoiceChange.bind(this);
    this.onPaymentAmountChange = this.onPaymentAmountChange.bind(this);
    this.validateAmount = this.validateAmount.bind(this);
    
    this.onFrequencyChange = this.onFrequencyChange.bind(this);
    this.onDurationChange = this.onDurationChange.bind(this);
    this.onNumberOfPaymentsChange = this.onNumberOfPaymentsChange.bind(this);
  }
  
  componentWillUnmount() {
    if (this.props.setMessages) {
      this.props.setMessages('');
    }
  }
  
  validateAmount() {
    this.setState({
      validAmount: this.props.validAmount
    });
  }

  onPaymentAmountChange(amount) {
    if (this.props.onPaymentAmountChange) {
      this.props.onPaymentAmountChange(amount);
    }
  }
  
  onPaymentInvoiceChange(invoice) {
    if (this.props.onPaymentInvoiceChange) {
      this.props.onPaymentInvoiceChange(invoice);
    }
  }

  onFrequencyChange(value) {
    if (this.props.onFrequencyChange) {
      this.props.onFrequencyChange(value);
    }
  }

  onDurationChange(value) {
    if (this.props.onDurationChange) {
      this.props.onDurationChange(value);
    }
  }
  
  onNumberOfPaymentsChange(value) {
    if (this.props.onNumberOfPaymentsChange) {
      this.props.onNumberOfPaymentsChange(value);
    }
  }

  invoice(allowInvoice, requireInvoice) {

    if(allowInvoice){
      let invoiceLabel = requireInvoice ? 'Invoice #' : 'Invoice # - optional'
      let isReadOnly = this.props.isInvoiceReadOnly
      return (
        <div className='row'>
          <FormInput
            id='invoice'
            name='invoice'
            type='text'
            validations='maxLength:50'
            width='102%'
            isReadOnly={ isReadOnly }
            validationErrors={ {
              'maxLength': 'This field 50 character maximum',
              "isDefaultRequiredValue":"invoice # is required"
            } }
            maxLength='50'
            //storeFormState={this.onPaymentAmountChange}
               required = {requireInvoice}
                //value={this.props.formdata.name}
                //isValid={ this.props.validNameOnCard || !this.state.validNameOnCardVisited }
                onChange={ this.onPaymentInvoiceChange } 
                //onBlur={ this.validateNameOnCard }
                value={ this.props.formdata.invoice }
                label={ invoiceLabel }
          />
        </div>
        )
     }
    else 
      return null;
  }

  recurringPaymentFields() {
    if (this.props.formdata.processingMethod == ProcessMethod.ONE_TIME)
    {
      return null;
    }
    // Note: PayOn field actually current part of recurring payment.
    // But potentially could be part of one time payment in the future was the initial idea.
    return (
      <RecurringPaymentFields 
        data={
          {
            frequency: this.props.formdata.frequency,
            duration: this.props.formdata.duration,
            numberOfPayments: this.props.formdata.numberOfPayments,
          }
        }
        onFrequencyChange={this.onFrequencyChange}
        onDurationChange={this.onDurationChange}
        onNumberOfPaymentsChange={this.onNumberOfPaymentsChange}
      />
    )
  }

  payOnDatePicker()
  {
    // if (!this.state.allowPayOnDate) 
    // {
    //   return null;
    // }
    if (this.props.formdata.processingMethod == ProcessMethod.ONE_TIME)
    {
      return null;
    }

    const payonValue = this.props.formdata.payon;
    const validPayOn = true;
    return (
      <div>
        <div className="row">
          <DatePicker
            validations={ {
              validateDateFormat: validateDateFormat
            } }
            validationErrors={ {
              'validateDateFormat': 'Invalid date',
              "isDefaultRequiredValue":"This field is required"
            } }
            required
            label='Pay On'
            name='payon'
            autoFocus={ this.hasAutoFocus('payment_payon') }
            id="payon"
            value={ payonValue }
            // isValid={ validPayOn }
            onPaymentPayonChange={ this.props.onPaymentPayonChange } />
        </div>
      </div>
    )
  }
  
  hasAutoFocus(fieldId) {
    if (this.props.location === undefined) return false;
    if (this.props.location.state === undefined) return false;

    return this.props.location.state.autoFocus == fieldId;
  }
  
  isSale() {
    return this.props.formdata.tranxType.toUpperCase() == 'SALE' || this.props.formdata.tranxType.toUpperCase() == 'S';
  }
  
  renderProcessingMethod() {
    // hide both buttons because if only one time is allowed no point in showing.
    // only allowable tranx type is sale or auth. so if its an auth we only allow one time so we don't even show the buttons to select recur or onetime.
    if (this.props.recurringEnabled && this.props.allowUserLogin && this.isSale()) {
      return (
        <ProcessingMethod
          formdata={this.props.formdata}
          onProcessingMethodChange={this.props.onProcessingMethodChange}
        />
      )
    } else {
      return null;
    }
  }
  
  componentDidMount() {
    if (this.props.location != null && this.props.location.pathname.toLowerCase() == '/payment') {
      // clear autofocus state for navigation here from review page
      if (this.props.history !== undefined) {
        this.props.history.replace({
          pathname: '/Payment',
          state: {}
        });
      }
    }
  }

  render() {
    const payonText = 'Pay On';
    const payonPlaceholder = 'Select Date';

    const payonValue =  this.props.formdata.payon || '';
    let amount = '';
    if (this.props.formdata.amount == 0 || this.props.formdata.amount)
    {
      amount = this.props.formdata.amount;
    }
    const isAuthOnly = this.props.formdata.tranxType != 'sale';
    const validAmount = this.state.validAmount;
    const validPayOn = this.state.validPayOn;
    const saleCeilingAmountLimit = this.props.saleCeilingAmount;
    
    
    return (
      <div className="payment-fields row">
        <div className="col-md-12">
          <div className="row">
            <AmountInputBox
                name='amount'
                validations={{
                  isAmountValid: function(values, value) {
                    if (isAuthOnly && value == 0)
                      return true;
                    return validateAmount(value);
                  },
                  isAmountValidWithSaleCeilingAmount:function(values, value){
                    if (!isAuthOnly && value > saleCeilingAmountLimit)
                      return false;
                    return true;
                  }
                }}
                validationErrors={{
                  'isAmountValid': 'Invalid amount',
                  'isDefaultRequiredValue': 'Amount value required',
                  'isAmountValidWithSaleCeilingAmount': 'Amount value exceeded the allowed limit'
                }}
                required
                isReadOnly= { this.props.isAmountReadOnly }                            
                label='Amount'
                            autoFocus={ this.hasAutoFocus('payment_amount') }
                            id="amount"
                            value={ amount }
                            // isValid={ validAmount }
                            placeholderText= ""
                            maxLength="13"
                            tooltipText="Dollar amount of the transaction upto 12 digits including 2 decimal places" 
                            onChange={this.onPaymentAmountChange}
                            //onValidate={this.validateAmount}
                            >
            </AmountInputBox>
          </div>

          
          {
            this.renderProcessingMethod()
          }

          {
            // invoice for recurring is used to record the recurring payment count
            // and is not used for customer input for recurring payments
            this.props.formdata.processingMethod == ProcessMethod.ONE_TIME 
              ? this.invoice(this.props.allowInvoice, this.props.requireInvoice)
              : null
          }

          {this.payOnDatePicker()}
          
          {this.recurringPaymentFields()}

        </div>

      </div>
    )
  }
}

export default Payment;
