import React, { Component } from 'react';
import { withFormsy } from 'formsy-react';
import './PaymentMethodInput.css';

export const PaymentMethod = {
  CC: 'CC',
  ACH: 'ACH'
}


class PaymentMethodInput extends Component {
  constructor(props){
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentWillUnmount() {
    if (this.props.onChange) {
      this.props.onChange(this.props.getValue());
    }
  }

  isNotAuthOnlyTransaction() {
    return this.props.tranxType.toUpperCase() != 'AUTH' && this.props.tranxType.toUpperCase() != 'A';
  }

  hasACH() {
    // assume undefined is they have both cc and ACH but should usually be set.
    return this.props.hasACH === undefined || this.props.hasACH;
  }

  onClick(event) {
  }

  onChange(event)
  {
    this.props.setValue(event.currentTarget.value);

    if (this.props.onChange) 
    {
      this.props.onChange(event.currentTarget.value);
    }
  }


  getStyleClasses()
  {
    var retClass = 'radio-button'
    

   //For radio button as checkbox apperence
    if(this.props.style == "checkbox")
    {
      retClass += " checkbox-Apperence";
    }
    else if (this.props.style == "verticalCheckbox")
    {
      retClass = "verticalCheckbox checkbox-Apperence";
    }

    if ( this.props.inError != null && this.props.inError == true ) 
    {
      retClass += ' radio-button_inputError'
    }

    return retClass;
  }

  renderCheckboxCC() {
    const type = this.props.type ? this.props.type : "radio"
    
    return (
      <span>
        <input 
          id={this.props.id}
          // name={name}
          type={type}      
          value={PaymentMethod.CC}
          title="Payment Method - Credit Card"
          checked={this.props.getValue() == PaymentMethod.CC}
          onBlur={this.onBlur}
          onClick={this.onClick}
          onChange={this.onChange}
        />
        <span>Pay with CC</span>
      </span>
    )
  }
  
  renderCheckboxACH() {
    const type = this.props.type ? this.props.type : "radio"

    if (this.hasACH() && this.isNotAuthOnlyTransaction()) {
      return (
        <span>
          <input 
            id={this.props.id}
            // name={name}
            type={type}      
            value={PaymentMethod.ACH}
            title="Payment Method - ACH"
            checked={this.props.getValue() == PaymentMethod.ACH}
            onBlur={this.onBlur}
            onClick={this.onClick}
            onChange={this.onChange}
          />
          <span>Pay with Checking Account</span>
        </span>
      )
    } else {
      return null;
    }
  }
  
  render() {
    const paymentMethodCCText = "Pay with CC";
    const paymentMethodACHText = "Pay with Checking Account";

    const placeholder = this.props.placeholder
    const value = this.props.getValue();
    const tooltip = this.props.tooltipText;
    const selected = this.props.getValue() ? true : false;
    const type = this.props.type ? this.props.type : "radio"
    const id = this.props.id;
    // const name = this.props.name;
    let styleClassName = this.getStyleClasses();
    return (
      <div className={styleClassName}>
        {this.renderCheckboxCC()}
        {this.renderCheckboxACH()}
      </div>
    );
  }
}

export default withFormsy(PaymentMethodInput);
