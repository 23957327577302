// Receipt
//
//  desc:
//    receipt page
//
//  props:
//    storedCustomer:       contains any storeCustomer info for a logged in user 
//
//

import React, { Component } from 'react';
import Table from '../../../Table';
import {FrequencyList} from '../../RecurringPaymentFields';
import Payment from '../../../PaymentForm/Steps/Payment';
import {GetCardType, CardType, GetCardTypeName} from '../../../CreditCard/CardTypes';
import './receipt.css';
import './receipt.small.css';


class Receipt extends Component {
  constructor(props){
    super(props);
  }
  
  // dont be confused with sale or auth only. naming might be off.
  hasStoredInfo(paymentType) 
  {
    let hasInfo = false;
    if (this.props.storedCustomer.token)
    { //we have a logged in customer
      //check to see if they have data for the piece in question
      if (paymentType.toLowerCase() == 'cc')
      { //check for credit card info
        if (this.props.storedCustomer.customer.Last4CC && this.props.storedCustomer.customer.ExpMnth && this.props.storedCustomer.customer.ExpYr)
        { //they have the proper credit card info stored in the customer profile
          hasInfo = true;
        }
      }
      else  //currently, the else is if we are checking for ACH stored info 
      {
        if (this.props.storedCustomer.customer.DDA && this.props.storedCustomer.customer.TR)
        { //they have the proper ACH info stored in the customer profile
          hasInfo = true;
        }
      }
    }

    return hasInfo;
  }
 
  records () {
    let paymentInfo = "";

    if (this.hasStoredInfo(this.props.formdata.paymentMethod))
    { //the logged in customer has stored info for the chosen payment type, so we want to show data for the correct type
      if (this.props.formdata.paymentMethod.toLowerCase() == 'cc')
      { //get stored info for CC payment type
        let last4 = this.props.storedCustomer.customer.Last4CC                    
        paymentInfo = '****' + last4;
      }
      else
      { //get stored info for ACH type
        let checkingAccount = this.props.storedCustomer.customer.DDA                    
        let routingNumber = this.props.storedCustomer.customer.TR       
        paymentInfo = 'Checking ' + checkingAccount
      }
    }
    else if (this.props.formdata.paymentMethod !== undefined && this.props.formdata.paymentMethod.toLowerCase() == 'cc') {
      const cc = this.props.formdata.cc;
      const type = GetCardTypeName(cc)
      paymentInfo = `${type} *${cc.substr(cc.length - 4)}`;
    } else {
      const checkNum = this.props.formdata.checkingAccount;
      if (checkNum !== undefined)
        paymentInfo = `Checking *${checkNum.substr(checkNum.length - 4)}`;
    }
    const obj = this.parseResponse();
    let tranxType = this.props.formdata.tranxType;
    if (tranxType.toUpperCase() == 'AUTH' || tranxType.toUpperCase() == 'A') {
      tranxType = 'Authorization'
    }
    if (tranxType.toUpperCase() == 'SALE' || tranxType.toUpperCase() == 'S') {
      tranxType = 'Sale'
    }

    const { payOn, disc } = this.props.formdata;
    const amt = Number(obj.amount) || 0; // included any convenience fee, if any
    const surchargeAmt = Number(obj.surcharge_amount) || 0;
    const totalAmount = (amt + surchargeAmt).toFixed(2);
    const disc_data = disc;
   

    if ( obj === undefined || obj === null ) {
      // console.log(this.props.location);
      // WHAT DO WE DO?
      return [
        {responseMessage: { title: 'Status', value: 'Could not process transaction' } }
        ,{ tranxId: { title: 'Reason', value: 'Unknown Error' } }
        ,{ payment_timestamp: { title: 'Timestamp', value: Date.now() } }
        ,{ total: { title: 'Total', value: `$${totalAmount}` } }
        ,{ payment_type: { title: 'Payment Type', value: tranxType } }
        ,{ payment_on: { title: 'Pay On', value: payOn } }
      ]
    }
    else if (!(obj.transaction_id > 0)){
      return [
        {responseMessage: { title: 'Status', value: 'Could not process transaction' } }
        ,{ tranxId: { title: 'Reason', value: obj.message } }
        ,{ payment_timestamp: { title: 'Timestamp', value: obj.timestamp } }
        ,{ total: { title: 'Total', value: `$${totalAmount}` } }
        ,{ payment_type: { title: 'Payment Type', value: tranxType } }
        ,{ payment_on: { title: 'Pay On', value: payOn } }
      ]
    }
    
    let responseMessage = 'Transaction Declined';
    // just cause vbscript and serialized boolean values might be a string.
    // checking for everything.
    if (obj.transaction_status !== undefined 
          && obj.transaction_status != null
          && (
              obj.transaction_status == 'True' || obj.transaction_status == 'true'
            )
        ) {
      responseMessage = 'Transaction Approved';
    }
    let records = [
      {responseMessage: { title: 'Status', value: responseMessage } }
      ,{ tranxId: { title: 'Transaction #', value: '#' + obj.transaction_id } }
      ,{ payment_timestamp: { title: 'Timestamp', value: obj.timestamp } }
      ,{ total: { title: 'Total', value: `$${totalAmount}` } }
      ,{ payment_type: { title: 'Payment Type', value: tranxType } }
      ,{ payment_on: { title: 'Pay On', value: payOn } }
    ]
    

    for (const item of disc_data) {
      if (item.hasOwnProperty('onReceipt') && item.onReceipt === true) { 
        let val = item.stored_value;
    
        if (Array.isArray(item.options) && !Array.isArray(item.stored_value)) {
          const selectedOption = item.options.filter(opt => opt.id === item.stored_value)[0];
          if (selectedOption !== undefined) {
            val = selectedOption.display;
          }
        }
      
        if (Array.isArray(item.options) && Array.isArray(item.stored_value)) {
          const selectedOptions = item.options.filter(opt => item.stored_value.includes(opt.id));
          const selectedOptionDisplays = selectedOptions.map(opt => opt.display);
          val = selectedOptionDisplays.join(', ');
        }
    
        records.push({ ['disc_'+item.input+'_'+item.id]: { title: item.title,  value: val } });
      }
    }
    
    if(surchargeAmt) {
      const paymentTimestampIndex = records.findIndex(record => Object.keys(record)[0] === 'payment_timestamp');

      records.splice(paymentTimestampIndex + 1, 0, 
        { transaction_amount: { title: 'Transaction Amount', value: `$${amt.toFixed(2)}` } },
        { surcharge_amount: { title: 'Surcharge Amount', value: `$${surchargeAmt.toFixed(2)}` } }
      );
    }
    
    return records.concat([
      { payment_card: {title: 'Payment Info', value: paymentInfo } }
    ]);

  }
  
  parseResponse() {
    try {
      const response = this.props.location.state;
      //console.log("response", response);
      return response;
    } catch(err) {
      console.log("Error parsing results from service", err);
    }
    return null;
  }
  
  render() {
    const records = this.records();
    return (
      <div className='receipt'>
        <Table rows={records}/>
      </div>
    );
  }
}

export default Receipt;
