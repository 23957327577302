// PaymentForm
//
//  desc:
//    Main payment link form
//
//  props:
//    isProcessing:     Used to lock down navigation controls while waiting for API response
//    formdata:         (required) the model for the form data and form validation
//    onSubmit:         (required) event handler for submitting payment
//    handleViewTerms   (optional) callback function for handling termslink click
//    location:         part of react router library used to determine what navigation to show
//                      and what page title and heading to show
//    storedCustomer:   contains any storeCustomer info for a logged in user 

/*
  PaymentForm
  
  The main body of payment link. Contains the header, the form,
  the company info and a few other components. This component is
  shared between Comus as the actual payment portal 
  and aphrodite as a payment portal sample.
  
  // NOTE: Some children component also does its own validation. And the parent 
  // here does validation to determine if should move next. We should
  // somehow consolidate logic. Makes sense to have the child component
  // own the logic but then where and who owns
  // the responsibility of maintaining the state of validity. And how
  // do we share it with the server validation. Right now its 
  // seems hacky and just getting it to all work. Most likely will never
  // get to it but I'm sure we are going to do more validation with other things
  // so at that time should redo this when we get a good solution. A jira would
  // get ignored and deleted and this comment will obviously get forgotten and ignored.
*/
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Switch, Redirect, withRouter } from "react-router-dom";

import {NewCustomerAccount, UpdateCustomerAccount} from '../../../../lib/comms';
import Payment from './Steps/Payment';
import Billing from './Steps/Billing';
import Review from './Steps/Review';
import Receipt from './Steps/Receipt';
import RecurringSummary from './Steps/RecurringSummary';
import {default as Header} from './HeaderSection';
import FormSection from './FormSection';
import CompanyInfo from '../CompanyInfo';
import Icon from '../Icon';
import {CCValidator} from '../CreditCard';
import {DEFAULT_PRIMARY, DEFAULT_SECONDARY} from '../DefaultProperties';
import {ProcessMethod} from './PaymentProcessMethod';

import Form from '../Form';
import './PaymentForm.css';

const DISC_DATA_INPUT_TYPE = 'text';

class PaymentForm extends Component {
  constructor(props){
    super(props);
    this.myForm = React.createRef();
    this.state = {
      focusOn: '',
      navigationEnabled: true,
      accountSavedSuccess: false,
      accountSavedMessage: '',
      isStoredCustomerProfileValid: false,
      isEditingCreditCardOrACH: false,
      isUpdatingStoredCustomerProfile: false,
      page: 'PAYMENT',
      enableNextButton: false,
      showDebug: null,
    }
    this.onCancelEditingCCOrACH =  this.onCancelEditingCCOrACH.bind(this);
    this.onEditingCCOrACH = this.onEditingCCOrACH.bind(this);
    this.onClearMessage = this.onClearMessage.bind(this);
    this.onSaveCustomer = this.onSaveCustomer.bind(this);
    this.disableNavigation = this.disableNavigation.bind(this);
    this.enableNavigation = this.enableNavigation.bind(this);
    this.validatePaymentStep = this.validatePaymentStep.bind(this);
    this.validateBillingStep = this.validateBillingStep.bind(this);
    this.validateAllBeforeSubmit = this.validateAllBeforeSubmit.bind(this);
    this.handleViewTerms = this.handleViewTerms.bind(this);
    this.onCheckForm = this.onCheckForm.bind(this);
    this.getRecaptchaToken = this.getRecaptchaToken.bind(this);
    this.getSiteKey = this.getSiteKey.bind(this);
  }
  
  onClearMessage() {
    this.props.onClearMessage();
  }
  
  onCancelEditingCCOrACH() {
    // console.log("onCancelEditingCCOrACH");
    this.setState({
      isEditingCreditCardOrACH: false,
    });
  }
  
  onEditingCCOrACH() {
    this.setState({
      isEditingCreditCardOrACH: true,
    });
  }

  validateAllBeforeSubmit(formdata) {
    return this.validatePaymentStep(formdata)
            && this.validateBillingStep(formdata)
            && this.validateTerms(formdata.agreeToTermsChecked)
            && this.validateRecaptcha(formdata.recaptchaToken);
  }

  // PAYMENT PAGE VALIDATION

  validatePaymentStep(formdata) {
    if (formdata.processingMethod == ProcessMethod.RECURRING) {
      return this.validatePayon(formdata.payon)
              && this.validateFrequency(formdata.frequency)
              && this.validateDuration(formdata.duration)
              && this.validateNumberOfPayments(formdata.duration, formdata.numberOfPayments);
    } else { // One-Time
      return this.validateAmount(formdata.amount)
              && !this.props.isProcessing;
    }
  }

  validateAmount(amount) {
    return Number(amount) > 0;
  }

  validatePayon(date) {
    // note date is a string.
    return date != '';
  }

  validateFrequency(frequency) {
    return frequency != undefined && frequency != '';
  }
  
  // continual or limited
  validateDuration(duration) {
    return duration != undefined && duration != '';
  }
  
  validateNumberOfPayments(duration, number) {
    // console.log(duration, number);
    if (duration == 'limited') {
      return number != undefined && number != '' && parseInt(number) > 0 && parseInt(number) < 999;
    } else {
      return true;
    }
  }

  // BILLING PAGE VALIDATION

  validateBillingStep(formdata) {
    let isValid = true;
    let storedInfo = false;           //if set, will indicate that a logged in customer has chosen to use stored billing info

    if (formdata.paymentMethod == 'CC') {
      if (this.props.storedCustomer.token && this.props.storedCustomer.customer.Last4CC && this.props.storedCustomer.customer.ExpMnth && this.props.storedCustomer.customer.ExpYr)
      { //the user is logged in and has a token
        // we do not have access to actual payment info on the client side
        // so assume that the payment data stored on the server is valid 
        // and continue
        isValid = true
        storedInfo = true                 //we set this to know what we dont need to worry about a valid password 
      }
      else 
      {
        //customer is not logged in, or does not have valid stored info... so check local billing info
        isValid = isValid && this.validateCC(formdata.cc);
        isValid = isValid && this.validateExp(formdata.exp);
        isValid = isValid && this.validateSecurityCode(formdata.securityCode);
      }
    } 
    else 
    { //check other payment method (which at this time is only ach) 
      if (this.props.storedCustomer.token && this.props.storedCustomer.customer.DDA && this.props.storedCustomer.customer.TR)
      { //the user is logged in and has a token
        // we do not have access to actual payment info on the client side
        // so assume that the payment data stored on the server is valid 
        // and continue
        isValid = true
        storedInfo = true                 //we set this to know what we dont need to worry about a valid password 

      }
      else
      { 
        //customer is not logged in, or does not have valid stored info... so check local billing info
        isValid = isValid && this.validateCheckingAccount(formdata.checkingAccount);
        isValid = isValid && this.validateRoutingNumber(formdata.routingNumber);
      }
    }
    
    if (formdata.processingMethod == ProcessMethod.RECURRING && storedInfo == false) 
    {
      // password should be blank if invalid and only have something if it is valid
      // because in paymentlink component (parent). It updates the state to empty string if is invalid.
      //.  Also, we only care about the password if they are not a logged in user using stored billing info
      isValid = isValid && this.validatePasswordField(formdata.password)
    }

    return isValid 
            && this.validNameOnCard(formdata.name)
            && this.validateAddress(formdata.address)
            && this.validateZipcode(formdata.zipcode)
            && this.validateCity(formdata.city)
            && this.validateState(formdata.state)
            && this.validateEmail(formdata.email)
            && this.validateDiscretionaryData(formdata.disc)

             //if i remember right this is for when there is a pending API call.
            && !this.props.isProcessing;
  }


  validateDiscretionaryData(discData)
  { //simple loop through the available discretionary data. 
    //. If there are any required fields, check to see that there is stored data for those fields
    //. Does not currently validate the stored data itself. Relies on the discretionary data component to ensure that only valid data is being selected/input/stored
    let isValid = true;
    discData.forEach(function(dataElement) 
    {
      if (dataElement.required == true)
      { //element is required, see that it has a stored_value
        
        let storedValue = dataElement.stored_value;
        
        if (storedValue == null || storedValue.length == 0)
        { //has not meant this min reqs for this data element, so return false to indicate that the discretionary data is not yet valid
          isValid = false;
        }
        else if(dataElement.input == DISC_DATA_INPUT_TYPE && storedValue != null && storedValue.length < dataElement.min_length)
        {
          isValid = false;
        }
      }
    }); 
    return isValid
  }

  // Validation hack job. Validation is everywhere is out of hand.
  // There has to be an easier way.
  validateCustomerFields(formdata) {
    var isValid = true;
    // console.log("cc", formdata.paymentMethod, formdata.cc)
    if (this.state.isEditingCreditCardOrACH) {
      if (formdata.paymentMethod == 'CC') {
        isValid = isValid && this.validateCC(formdata.cc);
        isValid = isValid && this.validateExp(formdata.exp);
      } else {
        isValid = isValid && this.validateCheckingAccount(formdata.checkingAccount);
        isValid = isValid && this.validateRoutingNumber(formdata.routingNumber);
      }
    }

    return isValid && this.validNameOnCard(formdata.name)
      && this.validateAddress(formdata.address)
      && this.validateZipcode(formdata.zipcode)
      && this.validateCity(formdata.city)
      && this.validateState(formdata.state)
  }

  validatePasswordField(password) {
    return password != undefined && password.trim().length > 0;
  }

  validNameOnCard(name) {
    return name !== undefined && name != '';
  }
  
  validateCC(cc) {
    // not implemented if customer logged in and has customer profile
    // then dont validate this
    return cc !== undefined && CCValidator.valid(cc);
  }
  
  validateExp(exp) {
    // regular expression does not text month greater than 12
    return exp !== undefined && /^[0-1]?[0-9]\/(?:[0-9]{2})?[0-9]{2}$/g.test(exp);
  }
  
  validateSecurityCode(securityCode) {
    return /^[0-9]{3,4}$/.test(securityCode) && securityCode !== undefined && securityCode != ''; 
  }
  
  validateCheckingAccount(checkingAccount) {
    return /^#*\d+$/.test(checkingAccount) && checkingAccount !== undefined && checkingAccount != '';
  }
  
  validateRoutingNumber(routingNumber) {
    return /^\d{9,}$/.test(routingNumber) && routingNumber !== undefined && routingNumber != '';
  }
  
  validateAddress(addresses) {
    return addresses !== undefined 
            && addresses.length >= 1
            && addresses[0] != '';
  }
  
  allowed_zip_pattern() {
    const country = this.props.formdata.country;
    if (country && country.toUpperCase() == 'US') {
      return /^\s*?\d{5}(?:[-\s]\d{4,})?\s*?$/;
    } else {
      return /^[a-zA-Z0-9\- ]+$/;
    }
  }
  
  validateZipcode(zipcode) {
    var re = this.allowed_zip_pattern();
    return zipcode !== undefined && re.test(zipcode);
  }
  
  validateCity(city) {
    return city !== undefined && city != '';
  }
  
  validateState(state) {
    return state !== undefined && state != '';
  }
  
  validateEmail(email) {
    return email !== undefined && 
          email.trim() != '' && 
          email !== undefined && /[\w-]+@([\w-]+\.)+[\w-]{2,}/.test(email);
  }
  
  // REVIEW PAGE VALIDATION

  handleViewTerms()
  {
    if (this.props.handleViewTerms)
    {
      this.props.handleViewTerms();
    }
  }

  validateTerms(agreeToTermsChecked) {
    return agreeToTermsChecked !== undefined && agreeToTermsChecked;
  }
  
  validateRecaptcha(recaptchaToken) {
    return recaptchaToken !== undefined && recaptchaToken !== null && recaptchaToken != '';
  }

  notifications() {
    return this.props.accountSavedMessage;
  }

  // RENDER COMPONENTS
  
  renderBackPaymentButton() {
    const disabled = !this.state.navigationEnabled;

    return (
      <div className='previous-button'>
        <Link to="/Payment">
          <button className='btn back' disabled={disabled}>
            <Icon className="fas fa-arrow-left" width="36" height="36"/>
            Back
          </button>
        </Link>
      </div>
    )
  }

  renderBackBillingButton() {
    const disabled = !this.state.navigationEnabled;

    return (
      <div className='previous-button'>
        <Link to="/Billing">
          <button className='btn back' disabled={disabled}>
            <Icon className="fas fa-arrow-left" width="36" height="36"/>
            Back
          </button>
        </Link>
      </div>
    )
  }

  renderContinueBillingButton(isEnabled) {
    const disabled = !isEnabled || !this.state.navigationEnabled;
    let buttonColor = disabled ? 'gainsboro' : this.getNavigationButtonColor();
    return (
      <div className='continue-button'>
        <Link to="/Billing">
          <button onClick={ this.onCheckForm }
                  className='btn'
                  style={{ backgroundColor: buttonColor }}>
            Continue to Billing
          </button>
        </Link>
      </div>
    )
  }
  
  // some craziness to force UI validation errors to be displayed on navigating next button
  // and focusing on whatever the reference was set to inside the form component.
  onCheckForm(event) {
    try {
      // Note: Example code to make a server validation and update the UI.
      // this.myForm.current.form.updateInputsWithError({
      //   Disc_text_1: 'this server validation test failed message',
      // })

      // Below we could use the reference to the form, Ex. this.myForm.current.form.state.isValid
      // Also isNextButtonDisabled seems like a better name to me.
      if (!this.state.enableNextButton) { 
        const theInnerFormsyRef = this.myForm.current.form.current;
        const inputs = theInnerFormsyRef.inputs;
        for (let key in inputs) {
          const item = inputs[key];
          if (!inputs[key].isValid()) {
            const component = window.formFields[item.props.name];
            if (component != undefined) {
              const element = window.formFields[item.props.name].myRef.current;
              element.scrollIntoView(false); //parameter align to bottom
              element.focus();
            } else {
              // the form field component that failed validationwas not configured correctly with a reference.
              // so just scroll up and set a message
              // this.props.setMessages("Please fix invalid fields");
              // window.scrollTo(0, window.scrollY - (window.scrollY - 100));
            }

            // The method scrollInfoView does not scroll pass the label with the error message.
            // The below does but not sure how it behaves with zoomed browser windows.
            // var viewportOffset = element.getBoundingClientRect();
            // var topPos = viewportOffset.top + window.scrollY;
            // console.log('element', element, topPos);
            // window.scrollTo(0, topPos - 10);
            break;
          }
        }
        // the click event hander that triggered this check form event is not actually the button
        // but the dom element below button. So prevent the event from bubbling up if there are validation errors.
        event.preventDefault();
        this.setState({
          showError: true // for now not used and obsolete
        })
      } else {
        this.setState({
          showError: false // for now not used and obsolete
        })
      }
    } catch(e) {
      // if for some reason the craziness up top breaks, don't navigate forward?! Hmm is that what i want?!!
      console.error(e);
      event.preventDefault();
    }
  }

  renderContinueReviewButton(isEnabled) {
    const disabled = !isEnabled || !this.state.navigationEnabled || this.props.disableSubmit;
    let buttonColor = this.getNavigationButtonColor();
    if (disabled) {
      buttonColor = 'gainsboro';
    }
    return (
      <div className='continue-button'>
        <Link to="/Review">
          <button onClick={ this.onCheckForm } 
              className='btn'
              disabled={disabled}
              style={{backgroundColor: buttonColor}}>
            Continue to Review
          </button>
        </Link>
      </div>
    )
  }

  renderPrintReceiptButton() {
    return (
      <div className='print-receipt-button no-print'>
        <button className='btn'
                style={{backgroundColor: this.getNavigationButtonColor()}}
                onClick={() => window.print()}>
          Print
        </button>
      </div>
    )
  }

  getSiteKey() {
    const siteKey = process.env.GOOGLE_RECAPTCHA_SITEKEY;
    return siteKey || '6LdeL7ocAAAAAL1Ko5fAwAQF_jEe_OHhL6yOGFgl'; //defaults to v3 test sitekey if not found in environment variable
  }

  getRecaptchaToken(e) {
    const recaptchaSiteKey = this.getSiteKey()

    window.grecaptcha.ready( () => {
      window.grecaptcha.execute(recaptchaSiteKey, { action: 'submit' }).then(token => {
        this.props.addRecaptchaTokenToState(token)
        this.props.onSubmit(e)
      });
    });
  }

  renderSubmitPaymentButton(isEnabled) {
    const disabled = (!isEnabled || this.props.disableSubmit)

    return (
      <div className='continue-button'>
        <button 
                onClick={
                  (e) => {
                    e.target.disabled = true;
                    this.getRecaptchaToken(e)
                  }
                }
                className='btn' 
                disabled={disabled} 
                style={{backgroundColor: this.getNavigationButtonColor()}}
        >
          Submit
        </button>
      </div>
    )
  }

  // Depending on what the URL route is it will call the appropriate method to render
  // buttons to navigate back and forth through the payment link pages. First it will validate
  // the form data that is passed into this component to make sure if it can go to the next page.
  renderNavigation() {
    // note this gets called on every rerender like when amount box changes
    if (this.props.location.pathname.toUpperCase() == "/PAYMENT") {
      // const isAllGood = this.validatePaymentStep(this.props.formdata);
      const isAllGood = this.state.enableNextButton;
      return (
        <div className='nav-buttons row'>
        { this.renderContinueBillingButton(isAllGood) }
        </div>
      )
    } else if (this.props.location.pathname.toUpperCase() == "/BILLING") {
      // const isAllGood = this.validateBillingStep(this.props.formdata);
      const isAckAgreementValid = this.validateTerms(this.props.formdata.agreeToSurchargeRecurAck);
      let isAllGood;

      if (this.props.formdata.surchargeAmount &&
          this.props.formdata.processingMethod === ProcessMethod.RECURRING &&
          this.props.formdata.paymentMethod === 'CC') {
        isAllGood = isAckAgreementValid && this.state.enableNextButton;
      } else {
        isAllGood = this.state.enableNextButton;
      }

      return (
        <div className='nav-buttons row'>
        { this.renderBackPaymentButton() }
          <div className='float-right-in-row'>
          { this.renderContinueReviewButton(isAllGood) }
          </div>
        </div>
      )
    } else if (this.props.location.pathname.toUpperCase() == "/REVIEW") {
      // bad const isAllGood = this.validateAllBeforeSubmit(this.props.formdata);
      const isAllGood = this.validateTerms(this.props.formdata.agreeToTermsChecked)
      return (
        <div className='nav-buttons row'>
        { this.renderBackBillingButton() }
          <div className='float-right-in-row'>
          { this.renderSubmitPaymentButton(isAllGood) }
          </div>
        </div>
      )
    } else if (this.props.location.pathname.toUpperCase() == "/RECEIPT") {
      return ( 
        <div className='nav-buttons row'>
        { this.renderPrintReceiptButton() }
        </div>
      )
    } else if (this.props.location.pathname.toUpperCase() == "/SUMMARY") {
      return ( 
        <div className='nav-buttons row'>
        { this.renderPrintReceiptButton() }
        </div>
      )  
    } else if (this.props.location.pathname.toUpperCase() == "/DESIGNER") {
      // aphrodite
      return (
        <div className='nav-buttons row'>
        { this.renderContinueBillingButton(false) }
        </div>
      )
    } else {
      return null;
    }
  }

  getNavigationButtonColor(){
    
    let lPrimaryColor = this.props.primaryColor.toLowerCase();
    let lSecondaryColor = this.props.secondaryColor.toLowerCase();
        
    if(lPrimaryColor == "#ffffff"  && lSecondaryColor == "#ffffff"){
      return DEFAULT_PRIMARY
    }
    
    if(lPrimaryColor == "#ffffff"){
      return lSecondaryColor
    } 
    
    return lPrimaryColor

  }
  
  disableNavigation() {
    this.setState({
      navigationEnabled: false,
    });
  }
  
  enableNavigation() {
    this.setState({
      navigationEnabled: true,
    });
  }

  // Method updates only
  onSaveCustomer() {
    const data = {
      merchant_id: sessionStorage.getItem('m'), //required
      // EMAIL: this.props.formdata.email, //required
      CC: this.props.formdata.cc, //required
      // Password: this.state.accountCreationPassword, //there is no check on VT
      DDA: this.props.formdata.checkingAccount, //there is no check on VT
      TR: this.props.formdata.routingNumber, //there is no check on VT

      BNAME: this.props.formdata.name, //name on card
      BADDRESS: this.props.formdata.address[0],
      BADDRESS2: this.props.formdata.address[1],
      BCITY: this.props.formdata.city,
      BSTATE: this.props.formdata.state,
      BZIP: this.props.formdata.zipcode,
      // VT legacy system when loading defaults overrides bcountry so not setting it for now
      //system uses 2 char country code uppercase
      BCOUNTRY: this.props.formdata.country.toUpperCase(),
      PHONE: this.props.formdata.phone,
      Exp: this.props.formdata.exp, //gets sent as month and year to VT
      token: this.props.storedCustomer.token,
    }

    UpdateCustomerAccount(data).then(function(resp) {
      if (resp !== undefined && resp.data !== undefined && (resp.data.status == 200 || resp.data.status == "200")) {
        this.setState({
          accountSavedSuccess: true,
          accountSavedMessage: resp.data.message,
        });
        // console.log("customer account udpated",this.props.storedCustomer)
        try {
          this.props.storedCustomer.updatePaymentInfo(this.props.formdata);
        } catch(err) {
          console.warn(err);
        }
        // this.onCancelEditingCCOrACH()
      } else {
        // console.log("resp", resp)
        this.setState({
          accountSavedSuccess: false,
          accountSavedMessage: resp.data.message,
        });
      }
      return resp;
    }.bind(this)).catch(function(err) {

      if (err.response.status === 404) {
        this.setState({
          accountSavedSuccess: false,
          accountSavedMessage: 'Network Error. Failed'
        });
      }
      this.setState({
        accountSavedSuccess: false,
        accountSavedMessage: 'Unexpected Error.'
      });
    }.bind(this));
  }

  renderDesigner(props) {
    // payon required by payment component. but probably should just check if it exists
    // passing in null to just make it show up.
    return (
      <Payment
        recurringEnabled={ this.props.recurringEnabled }
        formdata={{payon: null}}
      />
    )
  }
  
  renderPaymentStep(props) {
    // Note: should push most of these properties down to make
    // it cleaner. But it not as simple because of how the UX works imo.
    // If we had a context or application storage it would make things simplier.
    // props property really are the properties of the router so the page actually
    // has access to the route location property
    return (
      <Payment
        {...props}
        setMessages={ this.props.setMessages }
        focusOn={ this.state.focusOn }
        onPaymentAmountChange={ this.props.onPaymentAmountChange }
        onPaymentInvoiceChange={ this.props.onPaymentInvoiceChange }
        onProcessingMethodChange={ this.props.onProcessingMethodChange }
        
        onPaymentPayonChange={ this.props.onPaymentPayonChange }
        onFrequencyChange={ this.props.onFrequencyChange }
        onDurationChange={ this.props.onDurationChange }
        onNumberOfPaymentsChange={ this.props.onNumberOfPaymentsChange }

        formdata={ this.props.formdata }
        validAmount={ this.validateAmount(this.props.formdata.amount) }
        validPayOn={ this.validatePayon(this.props.formdata.payon) } 
        recurringEnabled={ this.props.recurringEnabled }
        allowUserLogin={ this.props.allowUserLogin }
        allowInvoice={ this.props.allowInvoice }
        requireInvoice={ this.props.requireInvoice }
        saleCeilingAmount={ this.props.saleCeilingAmount }

        isInvoiceReadOnly= { this.props.isInvoiceReadOnly }
        isAmountReadOnly= { this.props.isAmountReadOnly }
        />
    )
  }

  renderBillingStep(props) {
    // Note: should push most of these properties down to make
    // it cleaner. But it not as simple because of how the UX works imo.
    // props property really are the properties of the router so the page actually
    // has access to the route location property
    return (
      <Billing
        {...props}
        setMessages={ this.props.setMessages }
        showError={ this.state.showError }
        formdata={ this.props.formdata }

        onBillingPaymentMethodChange={ this.props.onBillingPaymentMethodChange }

        onBillingCreditCardNumberChange={ this.props.onBillingCreditCardNumberChange }
        onBillingCreditCardExpChange={ this.props.onBillingCreditCardExpChange }
        onBillingCreditCardSecurityCodeChange={ this.props.onBillingCreditCardSecurityCodeChange }

        onBillingCheckingAccountChange={ this.props.onBillingCheckingAccountChange }
        onBillingRoutingNumberChange={ this.props.onBillingRoutingNumberChange }

        onBillingCountryChange={ this.props.onBillingCountryChange }
        onBillingNameChange={ this.props.onBillingNameChange }
        onBillingAddressChange={ this.props.onBillingAddressChange }
        onBillingZipcodeChange={ this.props.onBillingZipcodeChange }
        onBillingCityChange={ this.props.onBillingCityChange }
        onBillingStateChange={ this.props.onBillingStateChange }
        onBillingPhoneChange={ this.props.onBillingPhoneChange }
        onBillingEmailChange={ this.props.onBillingEmailChange }
        onBillingSpecialInstructionChange={ this.props.onBillingSpecialInstructionChange }
        onDiscretionaryDataChange={ this.props.onDiscretionaryDataChange }
        
        validNameOnCard={ this.validNameOnCard(this.props.formdata.name) }
        validateCC={ this.validateCC(this.props.formdata.cc) }
        validateExp={ this.validateExp(this.props.formdata.exp) }
        validateSecurityCode={ this.validateSecurityCode(this.props.formdata.securityCode) }
        validateCheckingAccount={ this.validateCheckingAccount(this.props.formdata.checkingAccount) }
        validateRoutingNumber={ this.validateRoutingNumber(this.props.formdata.routingNumber) }
        validateAddress={ this.validateAddress(this.props.formdata.address) }
        validateZipcode={ this.validateZipcode(this.props.formdata.zipcode) }
        validateCity={ this.validateCity(this.props.formdata.city) }
        validateState={ this.validateState(this.props.formdata.state) }
        validateEmail={ this.validateEmail(this.props.formdata.email) }

        resetPassword={ this.props.onBillingPasswordChange }
        storedCustomer = {this.props.storedCustomer}                  //passing stored customer info to billing for displaying proper info for stored customer
        disableNavigation = { this.disableNavigation }
        enableNavigation = { this.enableNavigation }

        onUpdatingStoredCustomerProfile = { this.props.onUpdatingStoredCustomerProfile }
        onEditingCCOrACH = { this.onEditingCCOrACH }
        onCancelEditingCCOrACH = { this.onCancelEditingCCOrACH }
        onSaveStoredCustomerProfile = { this.onSaveCustomer }
        onClearMessage = { this.onClearMessage }
        notifications = { this.notifications() }
        isStoredCustomerProfileValid = { this.validateCustomerFields(this.props.formdata) }
        validateCustomerFields = { this.validateCustomerFields }
        isEditingCreditCardOrACH = { this.state.isEditingCreditCardOrACH }
      />
    )
  }

  renderReviewStep(props) {
    // Note: should push most of these properties down to make
    // it cleaner. But it not as simple because of how the UX works imo.
    // props property really are the properties of the router so the page actually
    // has access to the route location property
    return (
      <Review
        {...props}
        setMessages={ this.props.setMessages }
        onAgreementCheckboxChange={ this.props.onAgreementCheckboxChange }
        onRecaptchaChange={ this.props.onRecaptchaChange }
        formdata={ this.props.formdata }
        termsLink={ this.props.termsLink} 
        storedCustomer = {this.props.storedCustomer}                  //passing stored customer info to Review page for displaying proper info for stored customer
        />
    )
  }

  renderReceipt(props) {
    // Note: should push most of these properties down to make
    // it cleaner. But it not as simple because of how the UX works imo.
    return (
      <Receipt
        {...props}
        formdata={ this.props.formdata } 
        storedCustomer = {this.props.storedCustomer}                  //passing stored customer info to Receipt page for displaying proper info for stored customer
        />
    )
  }
  
  renderRecurringSummary(props) {
    return (
      <RecurringSummary
        {...props}
        formdata={ this.props.formdata } />
    )
  }

  render() {
    // logoOverlay property only meant to be set on the aphrodite sample.
    // Comus will pass in the different payment processing steps as children.
    // Payment, Billing, Review, and Receipt components.
    // Data is a required property for company info component.
    const primaryColor = this.props.primaryColor || DEFAULT_PRIMARY;
    const secondaryColor = this.props.secondaryColor || DEFAULT_SECONDARY;
    const logoOverlay = this.props.logoOverlay || null;
    const contactInfo = this.props.contactInfo;
    let validity = false;
    let inputs = null;
    let formModel = null;
    if (this.myForm.current) {
      // initially form validity is true. when navigation button is clicked it validates the form
      validity = this.myForm.current.form.current.state.isValid;
      formModel = this.myForm.current.form.current.getModel();
    }
    return (
      <div className='payment-form row'>
        <div className='col'>
          <div className='row'>
            <Header bgcolor={primaryColor} />
          </div>

          <div className='paymentform-body row'>
            <div className='company-info col-md-4'>
              <CompanyInfo
                  companyLogoOverlay={logoOverlay}
                  companyLogo={this.props.logoUrl}
                  primaryColor={primaryColor}
                  customTitle={this.props.customTitle}
                  companyName={contactInfo.name}
                  companyWebsite={contactInfo.website}
                  companyEmail={contactInfo.email}
                  handleEmail={this.handleEmail}
                  handleViewTerms={this.handleViewTerms}
                  handleLogoClick={this.handleLogoClick} />
            </div>

            <div className='col-md-8'>


<Form ref={this.myForm}
        onValidSubmit={(v) => {}}
        onValid={ () => this.setState({enableNextButton: true}) }
        onInvalid={ () => { this.setState({enableNextButton: false}) } }>
              <FormSection
                    message={ this.props.message }
                    setMessages={ this.props.setMessages }
                    allowUserLogin={ this.props.allowUserLogin }
                    onSetFieldsForUserProfile={ this.props.onSetFieldsForUserProfile }
                    secondaryColor={ secondaryColor } 
                    primaryColor={ primaryColor }
                    onEmailChange={ this.props.onBillingEmailChange }
                    onPasswordChange={ this.props.onBillingPasswordChange }
                    totalPaymentControl={ this.props.totalPaymentControl }
                    navigationBreadCrumbs={ this.props.navigationBreadCrumbs }
                    navigationPageHeader={ this.props.navigationPageHeader }
                    navigateControl={ this.renderNavigation() } 
                    formdata={ this.props.formdata }
                    showError={ this.state.showError }
                    emailReadonly={ this.props.storedCustomer.isLoggedIn() }>
                <Route path="/Payment" render={(props) => (
                  this.renderPaymentStep(props)
                )} />
                <Route path="/Billing" render={(props) => (
                  this.renderBillingStep(props)
                )} />
                <Route path="/Review" render={(props) => (
                  this.renderReviewStep(props)
                )} />
                <Route path="/Receipt" render={(props) => (
                  this.renderReceipt(props)
                )} />
                <Route path="/designer" render={(props) => (
                  this.renderDesigner(props)
                )} />
                <Route path="/Summary" render={(props) => (
                  this.renderRecurringSummary(props)
                )} />
              </FormSection>
</Form>
            </div>

          </div>
        
        </div>
      </div>
    );
  }
}

export default withRouter(PaymentForm);
