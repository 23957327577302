import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { customerLogin, forgotCustomerLogin } from '../../../../lib/comms';


export const UserContext = React.createContext({ username: '', token: null });

// Not implemented storing stateful variables in session because in order
// to attempt to make application stateful or truely allow login.
// We must add another endpoint to retrieve customer info via token.
// Or we need to somehow store the retrieved value from memory.
// But right now if customer refreshes page they will have to log back in.
// So it's really not logging in but more of go fetch my profile to fill the form.
// Also everytime customer logs back in a new cart token is created on the backend.
// There will be orphan records since there is no way to log out.
// And there is not token expiration clean up. There is a mechanism I believe
// in legacy where it sorta cleans it up.
class UserContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.forgotLogin = this.forgotLogin.bind(this);
    this.updatePaymentInfo = this.updatePaymentInfo.bind(this);

    // gets cleared out on refresh.
    this.state = {
      isLoggedIn: function() {
        return this.token != null;
      },
      updatePaymentInfo: this.updatePaymentInfo,
      username: null,
      token: null,
      forgotLogin: this.forgotLogin,
      login: this.login,
      customer: null,
    };
  }
  
  updateCustomerInfoValues(newCustomer, formdata) {

    if (typeof(formdata.address[0]) === 'string'
      && formdata.address[0].trim() != "") {
      newCustomer.BADDRESS = formdata.address[0].trim();
    }
    if (typeof(formdata.address[1]) === 'string'
      && formdata.address[1].trim() != "") {
      newCustomer.BADDRESS2 = formdata.address[1].trim();
    }
    if (typeof(formdata.city) === 'string'
      && formdata.city.trim() != "") {
      newCustomer.BCITY = formdata.city.trim();
    }
    if (typeof(formdata.country) === 'string'
      && formdata.country.trim() != "") {
      newCustomer.BCOUNTRY = formdata.country.trim();
    }
    if (typeof(formdata.state) === 'string'
      && formdata.state.trim() != "") {
      newCustomer.BSTATE = formdata.state.trim();
    }
    // note that on the form element is is zip. on the state model it is zipcode
    if (typeof(formdata.zip) === 'string'
      && formdata.zip.trim() != "") {
      newCustomer.BZIP = formdata.zip.trim();
    }
    if (typeof(formdata.email) === 'string'
      && formdata.email.trim() != "") {
      newCustomer.EMAIL = formdata.email.trim();
    }
    if (typeof(formdata.phone) === 'string'
      && formdata.phone.trim() != "") {
      newCustomer.PHONE = formdata.phone.trim();
    }

  }
  
  updatePaymentInfo(formdata) {
    const newCustomer = this.state.customer;
    
    this.updateCustomerInfoValues(newCustomer, formdata);

    if (typeof(formdata.cc) === 'string'
      && formdata.cc.trim() != "") {
      newCustomer.Last4CC = formdata.cc.substr(formdata.cc.length - 4);
    }
    // console.log("exp:", formdata.exp);
    if (formdata.exp && formdata.exp.split("/").length == 2) {
      newCustomer.ExpMnth = formdata.exp.split("/")[0]
      newCustomer.ExpYr = formdata.exp.split("/")[1]
    }
    // console.log("routingNumber:", formdata.routingNumber, newCustomer);
    if (typeof(formdata.routingNumber) === 'string' 
          && formdata.routingNumber.trim() != "")
    {
      const routing = formdata.routingNumber.trim();
      newCustomer.TR = routing.substr(routing.length - 4);
    }
    // console.log("checkingAccount:", formdata.checkingAccount, newCustomer);
    if (typeof(formdata.checkingAccount) === 'string'
          && formdata.checkingAccount.trim() != "")
    {
      const dda = formdata.checkingAccount.trim();
      newCustomer.DDA = dda.substr(dda.length - 4);
    }
    
    if (formdata.name) {
      newCustomer.BNAME = formdata.name;
    }

    this.setState({
      customer: newCustomer
    });
  }

  forgotLogin(username) {
    const merchantId = sessionStorage.getItem('m');
    // console.log(username)
    // console.log(merchantId)

    return forgotCustomerLogin(username, merchantId).then(function(resp) {
      return {
        success: resp["status"] == 200 || resp["status"] == "200" || resp["status"] == "ok",
        message: resp.status_message,
      }
    }.bind(this)).catch(function(err) {
      return {
        success: false,
        message: "Unexpected error reset password. Please contact site admin.",
      }
    });
  }

  login(username, password) {
    const merchantId = sessionStorage.getItem('m');


    return customerLogin(username, password, merchantId, null).then(function(resp) {
      if (resp["status"] == 200 || resp["status"] == "ok") {

        this.setState({
          token: resp.token,
          customer: resp.customer,

        });

        return {
          success: true,
          message: resp.message,
          token: resp.token,
          ...resp.customer,
        }
      } else {
        return {
          success: false,
          message: resp.message,
        }
      }
    }.bind(this)).catch(function(err) {
      return {
        success: false,
        message: "error logging in",
      }
    });
  }

  render() {
    // set the value of provider from component state
    return (
      <UserContext.Provider value={this.state}>
      { this.props.children }
      </UserContext.Provider>
    )
  }
}

export default UserContextProvider;

// Example of what current response will include.

// BADDRESS: "3233 80th"
// BADDRESS2: ""
// BCITY: "Lacey"
// BCOUNTRY: "US"
// BNAME: "John"
// BSTATE: "WA"
// BZIP: "98516"
// CustomerID: "username@paytrace.com"
// Description: ""
// EMAIL: "john@paytrace.com"
// ExpMnth: "05"
// ExpYr: "20"
// FAX: ""
// Last4CC: "5454"
// PHONE: "3609333333"
// SADDRESS: ""
// SADDRESS2: ""
// SCITY: ""
// SCOUNTRY: "US"
// SCOUNTY: ""
// SNAME: ""
// SSTATE: ""
// SZIP: ""
// TIMESTAMP: "6/26/2019 11:40:59 AM"
// TaxID: ""
// TransactionIdentifier: ""
// USERNAME: "Cart"
