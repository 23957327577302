// Country
//
//  desc:
//    dropdown control consisting of countries. Uses a library for the list of existing countries.
//
//  props:
//    value:                (optional) the country
//
import React, { Component } from 'react';
import {GetCountries} from './CountryList';
import DropDown from '../DropDown';
import DropDownIcon from '../resources/images/dropdown.png';
import { withFormsy } from 'formsy-react';
import './country.css';


var dropDownStyle = {
  backgroundImage: `url(${DropDownIcon})`
}


class Country extends React.PureComponent {
  constructor(props)
  {
    super(props);
    this.options = GetCountries();
    this.onChange = this.onChange.bind(this);
  }
  
  componentWillUnmount() {
    if (this.props.onChange) {
      this.props.onChange(this.props.getValue());
    }
  }

  onChange(event) {
    this.props.setValue(event.currentTarget.value)
    //this.props.onChange(event.target.value);
  }

  render() {
    let options = Object.keys(this.options).map((key, index) => 
      <option key={index} value={key}>{this.options[key]}</option>
    );
    const requiredColor = this.props.isValid() ? null : { color: 'red' }
    const requiredMark = this.props.required ? <span className='required-mark' style={ requiredColor }>*</span> : null;
    return (
      <div className="Country">
        <div className="label">
          <label htmlFor={this.props.id}>Country{ requiredMark }</label>
        </div>
        <select
              id={this.props.id}
              value={this.props.getValue() || ''}
              onChange={this.onChange}
              style={dropDownStyle}>
          {options}
        </select>
      </div>
    );
  }
}

export default withFormsy(Country);
