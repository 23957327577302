// FormSection
//
//  desc:
//    Renders the current form step title and
//    Renders the current form step in the process (Payment, Billing, Review, and ...).
//    Renders the navigation control as a child component passed in.
//    Renders the breadcrumbs for the available steps.
//    Renders the total payment component.
//
//  props:
//    children:             (required) the current step in the payment process. 
//                              Component should not be wrapped and uses key value to 
//                              determine what the current step is.
//
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
import HeaderLabel from '../../HeaderLabel';
import TotalPaymentBox from '../../TotalPaymentBox';
import Icon from '../../Icon';
import NavigationButtons from '../NavigationButtons';
import LoginWidget, {CreateAccount} from '../../LoginWidget';
import PasswordNew from '../../PasswordNew';
import EmailAddressInput from '../../EmailAddressInput';
import UserContextProvider, { UserContext } from '../../LoginWidget/UserContext';
import {NewCustomerAccount} from '../../../../../lib/comms';
import { ProcessMethod } from '../PaymentProcessMethod';
import FlashNotification from '../../FlashNotification';
import './Form.css';
import './form.med.css';
import './form.small.css';

/*
 * Section of the form that will display the step child component containing.
 * current step to display. Navigation component navigates through the 
 * children by calling the callback handler to update the index state 
 * representing the current step. The top component keeps track of the 
 * current index and propagates that value down to any child that needs to know.
 */
class FormSection extends Component {
  constructor(props){
    super(props);
    this.state = {
      accountCreationPasswordValid: false,
      accountCreationPassword: null,
      accountCreatedSuccess: false,
      accountCreationMessage: '',
    }
    this.renderAccountPasswordField = this.renderAccountPasswordField.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onCreateAccount = this.onCreateAccount.bind(this);
    this.createCustomer = this.createCustomer.bind(this);
    this.onCreateAccountPasswordChange = this.onCreateAccountPasswordChange.bind(this);
  }
  
  createCustomer(evt) {

    // So current UX is that the account creation is on the receipt page
    // At this time CC (or DDA), email and all other required values should
    // exists. Note the PROBLEM is validation on the server for customer creation
    // is independant of the client code. So for example if lets say EMAIL is not
    // required for some reason on Comus and the server thinks it is invalid and
    // and returns response saying creation failed. THEN WHAT? where do we go? they
    // are already on the receipt page. Either its bad UX or you have to do some
    // funky things to go back and fix things! Not gonna work.
    const data = {
      merchant_id: sessionStorage.getItem('m'), //required
      EMAIL: this.props.formdata.email, //required
      CC: this.props.formdata.cc, //required
      Password: this.state.accountCreationPassword, //there is no check on VT
      DDA: this.props.formdata.checkingAccount, //there is no check on VT
      TR: this.props.formdata.routingNumber, //there is no check on VT
    
      BNAME: this.props.formdata.name, //name on card
      BADDRESS: this.props.formdata.address[0],
      BADDRESS2: this.props.formdata.address[1],
      BCITY: this.props.formdata.city,
      BSTATE: this.props.formdata.state,
      BZIP: this.props.formdata.zipcode,
      // VT legacy system when loading defaults overrides bcountry so not setting it for now
      //system uses 2 char country code uppercase
      BCOUNTRY: this.props.formdata.country.toUpperCase(),
      PHONE: this.props.formdata.phone,
      Exp: this.props.formdata.exp, //gets sent as month and year to VT
    }

    NewCustomerAccount(data).then(function(resp) {
      if (resp !== undefined && resp.data !== undefined && (resp.data.status == 200 || resp.data.status == "200")) {
        this.setState({
          accountCreatedSuccess: true,
        });
      } else {
        this.setState({
          accountCreatedSuccess: false,
          accountCreationMessage: resp.data.message.replace("Please use the log in section to log into your stored profile and complete your transaction.<BR />", "Please use your email address and try to log in as a returning user to complete your transaction."),
        });
      }

    }.bind(this)).catch(function(err) {

      if (err.response.status === 404) {
        this.setState({
          accountCreatedSuccess: false,
          accountCreationMessage: 'Network Error. Failed'
        });
      }
      this.setState({
        accountCreatedSuccess: false,
        accountCreationMessage: 'Unexpected Error.'
      });
    }.bind(this));
  }

  // Receipt page has a form button to create a customer account for onetime payments.
  // For recurring payments the customer account is created automatically because an account is required.
  onCreateAccount(evt) {
    if (this.state.accountCreationPasswordValid) {
      // email should be valid and exist at this point
      this.createCustomer()
    }
  }
  
  onEmailChange(isValid, value) {
    if (this.props.onEmailChange) {
      this.props.onEmailChange(isValid, value);
    }
  }
  
  // Password input field events for a one time payment on receipt page.
  // Create button calls onCreateAccount and creates it if password valid.
  onCreateAccountPasswordChange(isValid, value) {
    if (isValid) {
      this.setState({
        accountCreationPasswordValid: true,
        accountCreationPassword: value
      });
    } else {
      this.setState({
        accountCreationPasswordValid: false,
      });
    }
  }
  
  // On BILLING page, password input field events for a recurring payment.
  // Account is created on submitting recurring payment.
  onPasswordChange(isValid, value) {
    if (this.props.onPasswordChange) {
      this.props.onPasswordChange(isValid, value);
    }
  }
  
  // On the RECEIPT page, password input field for a onetime payment.
  // Note, the renderAccountPasswordField method
  // renders password fields on the billing form for recurring payments.
  // workflow is very wonky which makes the code very wonky.
  renderAccountCreation(context) {
    if (this.isLoggedIn(context) || !this.props.allowUserLogin) return null;

    let createAccountWithCompany = "";
    if (this.props.companyName) {
      createAccountWithCompany = 'with' + this.props.companyName;
    } 
    if (this.state.accountCreatedSuccess) {
      return (
        <div style={{marginTop:'20px', marginLeft: '-25px'}} className='user-account-fields col-12 no-print'>
          <div>
            <h1>Account Created</h1>
            <div>Your payment information has been saved.</div>
          </div>
        </div>
      )
    }

    return (
      <div style={{marginTop:'20px', marginLeft: '-25px'}} className='user-account-fields col-12 no-print'>
        <div>
          <label>Create an Account</label>
          <div>Save your information for future payments {createAccountWithCompany} by creating an account.</div>
        </div>

        <div>
          { /* shows up on the receipt page to allow creating an account */ }
          <PasswordNew
            name='password'
            isPasswordValidCallback={this.onCreateAccountPasswordChange}/>
        </div>

        <div>
          <button disabled={!this.state.accountCreationPasswordValid} onClick={this.onCreateAccount} className='btn' style={{ color: '#ffffff', height: '50px', backgroundColor: 'rgb(70, 150, 202)' }}>
            Create Account
          </button>
          {
            (this.state.accountCreationMessage.trim() != '') ? (
              <label>
                <div style={{color: 'red', paddingLeft: '10px'}}>
                {this.state.accountCreationMessage}
                </div> 
              </label>
            ) : null
          }
        </div>
      </div>
    )
  }

  renderNavigation() {
    if (this.props.navigateControl) {
      return this.props.navigateControl;
    }
    return null;
  }
  
  renderTotalPaymentControl() {
    if (this.props.totalPaymentControl) {
      return this.props.totalPaymentControl;
    }
    return null;
  }
  
  renderBreadCrumbs() {
    if (this.props.navigationBreadCrumbs) {
      return this.props.navigationBreadCrumbs;
    }
    return null;
  }
  
  renderPageHeader() {
    if (this.props.navigationPageHeader) {
      return this.props.navigationPageHeader;
    }
    return null;
  }

  renderCurrentPage() {
    // The pages like "payment", "billing", "review" and "receipt" determined by Steps component.
    return (
        <div className='row'>
          <div className='col-lg-7'>
          {this.props.children}
          </div>
          <div className='col-lg-5'>
          {
            (this.props.location.pathname.toUpperCase() != "/RECEIPT" 
                && this.props.location.pathname.toUpperCase() != "/SUMMARY"
                && this.props.allowUserLogin) ? (
              <LoginWidget onSetFieldsForUserProfile={this.props.onSetFieldsForUserProfile} />
            ) : null
          }
          </div>
        </div>

    )
  }
  
  isLoggedIn(context) {
    // note the only time this will have a value is
    // is when the login component is used to login and will
    // not be logged in initially because the state of the 
    // login provider has an initial value of null.
    if (context.token == null) {
      return false;
    } else {
      return true;
    }
  }
  
  isRecurring() {
    if (this.props.formdata) {
      return this.props.formdata.processingMethod == ProcessMethod.RECURRING;
    } else {
      return false;
    }
  }
  
  // On the BILLING page, password input field for a recurring payment.
  // Note, the renderAccountCreation method
  // renders password fields on the billing form for recurring payments.
  // workflow is very wonky which makes the code very wonky.
  renderAccountPasswordField(context) {
    if (this.isRecurring() && !this.isLoggedIn(context) ) {
      return (
        <div>
          <br/>
          <br/>
          <h5>
            Account is used to create a recurring payment
            <span className='required-mark'>*</span>
          </h5>
          <div>
            { /* is required and only shows up for recurring payments on the billing page */ }
            <PasswordNew
              validations={{
                passwordValid: function(values, value) {
                  // password component does its own validation
                  // I do not feel like refactoring password component.
                  return value;
                }
              }}
              validationErrors={{
                'passwordValid': '(Valid password required to create account for recurring payment)'
              }}
              innerRef={c => {
                window.formFields['password'] = c
              }}
              name='password'
              isPasswordValidCallback={this.onPasswordChange}/>
          </div>
        </div>
      )
    } else {
      return null;
    }
  }
  
  renderMessage() {
    let message = "";
    if (this.props.message) {
      // hacky clean up some messages returned from endpoint or just show message.
      message = this.props.message.replace("Please use the log in section to log into your stored profile and complete your transaction.<BR />", "Please use your email address and try to log in as a returning user to complete your transaction.");
    }
    if (message && message.trim() != '') {
      return (
        <FlashNotification
          type='warning'
          message={message}
          onClose={(evt) => this.props.setMessages('')} 
          // flashDisplayTime={5000}
        />
      )  
    } else {
      return null;
    }
  }
  
  render() {
    return (
      <div className='paymentform-form'>
        <div className='row'>
          <div className='col-md-12'>
            <div className="row">
              {this.renderBreadCrumbs()}
            </div>

            <div className="row">
              {this.renderPageHeader()}
            </div>
            
            { this.renderCurrentPage() }
            
            {
              // Might be better to put this on the billing page
              // but what if user logs in from review page...
              // TODO when doing Recurring
              // if customer is logged in then shouldn't need to show
              // or maybe at least have the email field read only
              // and set value='<email address from login>' on email component.
              (
                this.props.location.pathname.toUpperCase() == "/BILLING"
              ) ? (
                
                <div style={{marginTop:'20px'}} className='user-account-fields row'>
                  <div className='col-7'>
                    <div>
                          <div>
                            <EmailAddressInput
                              maxLength={50}
                              id='email'
                              name='email'
                              validations='isEmail'
                              validationErrors={ {
                                'isEmail': 'Invalid email',
                                "isDefaultRequiredValue":"This field is required"
                              } }
                              required
                              title='Email'
                                readonly={this.props.emailReadonly} 
                                value={this.props.formdata.email} 
                                onTextInputCallback={this.onEmailChange}
                              />
                          </div>
                          <UserContext.Consumer>
                          {
                            this.renderAccountPasswordField
                    
                          }
                          </UserContext.Consumer>
                    </div>
                  </div>
                </div>
                
              ) : null
            }
          </div>
        </div>

        <div className='total-section row'>
          {this.renderTotalPaymentControl() }
        </div>

        <div className='row no-print'>
        { this.renderNavigation() }
        </div>
        

        { this.renderMessage() }


        <UserContext.Consumer>
        {
          // NOTE could put it in the receipt component but then the UI
          // would be off.
          (context) => {
            return (
              ( !this.isRecurring() && this.props.location.pathname.toUpperCase() == "/RECEIPT" ) ? 
              this.renderAccountCreation(context)
              : null
          )}
        }
        </UserContext.Consumer>

      </div>
    );
  }
}


FormSection.propTypes = {
  message: PropTypes.string,
  setMessages: PropTypes.func.isRequired,
}
export default withRouter(FormSection);
